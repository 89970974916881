import React from 'react';
import './Home.css';
import AboutMe from './AboutMe';
import Socials from './Socials';
import Video from './Video';

const Home = () => {
    return (
        <div>
            <AboutMe />
            <Socials />
            <Video />
        </div>
    );
};

export default Home;