import React, { useEffect } from 'react'; 
import './AboutMe.css';
import picture_1 from '../assets/picture_1.jpg';

const AboutMe = () => {
    // Add useEffect hook for touch interaction
    useEffect(() => {
        const imgElement = document.querySelector('.about-me-left img');
        const toggleAnimation = () => {
            imgElement.classList.toggle('active');
            window.navigator.vibrate(200); // Vibrate for 200ms
        };

        // Add touch event listener
        imgElement.addEventListener('touchstart', toggleAnimation);

        // Clean up event listener on component unmount
        return () => {
            imgElement.removeEventListener('touchstart', toggleAnimation);
        };
    }, []); 

    // Handle click event to navigate to YouTube channel
    const handleImageClick = () => {
        window.location.href = 'https://www.youtube.com/@faultyMAIN';
    };

    return (
        <div className="about-me">
            <div className="about-me-left">
                <h2>Profile</h2> {/* Ensure heading is above the image */}
                <img src={picture_1} alt="Profile" onClick={handleImageClick} />
            </div>
            <div className="about-me-right">
                <p>I'm faulty! An extremely high-efforted ROBLOX youtuber that plays Welcome to Bloxburg.</p>
            </div>
        </div>
    );
};

export default AboutMe;