import React, { useEffect, useState } from 'react';
import './Video.css';

// Manually provided video details (except for views, which will be fetched from backend)
const VIDEO_DETAILS = [
    {
        id: 'W0xMh38lWgw',
        title: 'Building a Beach House in Bloxburg',
        uploadDate: '6/26/2024',
        description: `In this video, I build a beach house in Bloxburg. Watch as I take you through the process step by step!`
    },
    {
        id: 'dUxxslUWcC8',
        title: 'Renovating the Bloxburg Starter House Into a Realistic House',
        uploadDate: '2/25/2023',
        description: `I completely transformed the Bloxburg starter house into something much more realistic. Let me show you how!`
    }
];

function Video() {
    const [videosFromBackend, setVideosFromBackend] = useState([]);

    useEffect(() => {
        const fetchViewsFromBackend = async () => {
            try {
                // Make a request to your backend to get video statistics (views)
                const response = await fetch('https://faulty-da2fdabf5c9c.herokuapp.com/api/videos'); // Use the Heroku URL
                const data = await response.json();

                // Update state with the fetched videos from the backend
                setVideosFromBackend(data);
            } catch (err) {
                console.error('Error fetching video details from backend:', err);
            }
        };

        fetchViewsFromBackend();
    }, []);

    // Helper function to get views for each video from the backend data
    const getViewsForVideo = (id) => {
        const video = videosFromBackend.find((v) => v.id === id);
        return video ? video.views : 'Loading...';
    };

    return (
        <div className="video-section">
            <h1>Featured Content</h1>
            {VIDEO_DETAILS.map((video) => (
                <div className="video-container" key={video.id}>
                    <iframe
                        title={video.title}
                        src={`https://www.youtube.com/embed/${video.id}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                    <div className="video-details">
                        <p><strong>Views:</strong> {getViewsForVideo(video.id)}</p>
                        <p><strong>Uploaded on:</strong> {video.uploadDate}</p>
                        <p><strong>Description:</strong> {video.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Video;
