import React from 'react';
import './Gallery.css';
import Gallery1 from '../assets/Gallery1.png';
import Gallery2 from '../assets/Gallery2.png';
import Gallery3 from '../assets/Gallery3.png';
import Gallery4 from '../assets/Gallery4.png';

const Gallery = () => {
    return (
        <div className="gallery-container">
            <div className="gallery-item">
                <img src={Gallery1} alt="Modern Beach House" />
                <p>Modern beach house featuring elevated structure and coastal design elements.</p>
            </div>
            <div className="gallery-item">
                <img src={Gallery2} alt="Elegant Suburban Home" />
                <p>Elegant suburban home with a traditional gabled roof and vibrant landscaping.</p>
            </div>
            <div className="gallery-item">
                <img src={Gallery3} alt="High School Complex" />
                <p>A modern high school complex with a sleek design and contemporary architecture.</p>
            </div>
            <div className="gallery-item">
                <img src={Gallery4} alt="Urban Street" />
                <p>Quiet urban street with overhead power lines and a residential feel.</p>
            </div>
        </div>
    );
};

export default Gallery;
