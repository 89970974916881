import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Header.css';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogoClick = () => {
        if (location.pathname === '/') {
            // If already on the homepage, scroll to the top
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            // Navigate to the homepage
            navigate('/');
        }
    };

    return (
        <header className="header">
            {/* Clicking on 'faulty' navigates or scrolls */}
            <h1 onClick={handleLogoClick} style={{ cursor: 'pointer' }}>faulty</h1>
            <nav className={isOpen ? 'nav-mobile' : ''}>
                <button onClick={() => setIsOpen(!isOpen)}>
                    Menu
                </button>
                <ul className={isOpen ? 'nav-links open' : 'nav-links'}>
                    <li><Link to="/" onClick={() => setIsOpen(false)}>Home</Link></li>
                    <li><Link to="/contact" onClick={() => setIsOpen(false)}>Contact</Link></li>
                    <li><Link to="/gallery" onClick={() => setIsOpen(false)}>Gallery</Link></li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
