import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <p>Email: info@isaacfaulty.com</p>
            <p>© 2024 All Rights Reserved</p>
        </footer>
    );
};

export default Footer;