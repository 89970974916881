import React from 'react';
import './Socials.css';
import InstagramLogo from '../assets/IGICON.png';
import YoutubeLogo from '../assets/YTICON.png';
import RobloxLogo from '../assets/RBLXICON.png';
import TiktokLogo from '../assets/TIKTOKICON.png';
import TwitterLogo from '../assets/TWTICON.png';

const Socials = () => {
    const logos = [
        { href: "https://www.youtube.com/@faultyMAIN", src: YoutubeLogo, alt: "YouTube" },
        { href: "https://x.com/notflty", src: TwitterLogo, alt: "Twitter" },
        { href: "https://www.instagram.com/isaacfaulty?igsh=OWp2OHI2emg1M3Y5&utm_source=qr", src: InstagramLogo, alt: "Instagram" },
        { href: "https://www.tiktok.com/@isaacfaulty", src: TiktokLogo, alt: "TikTok" },
        { href: "https://www.roblox.com/groups/7712018/faultys-OFFICIAL-group#!/about", src: RobloxLogo, alt: "Roblox" },
    ];

    return (
        <div className="socials">
            <div className="socials-inner">
                {/* First Set of Social Links */}
                <div className="logo-container">
                    {logos.map((logo, index) => (
                        <a key={`first-${index}`} href={logo.href} target="_blank" rel="noopener noreferrer">
                            <img src={logo.src} alt={logo.alt} />
                        </a>
                    ))}
                </div>
                {/* Duplicate Set of Social Links */}
                <div className="logo-container">
                    {logos.map((logo, index) => (
                        <a key={`second-${index}`} href={logo.href} target="_blank" rel="noopener noreferrer">
                            <img src={logo.src} alt={logo.alt} />
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Socials;
