import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <div className="contact">
            <h1>Contact Page</h1>
            <p>If you have any questions that you'd like me to answer or suggestions you'd like me to consider, use the link below to reach out to me!</p>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLScJOTn37J65lzzwZ_yhvSVVc6-dFEs3JG31spJM6P32whtlIQ/viewform" target="_blank" rel="noopener noreferrer">
                <button className="contact-button">Suggestions for videos or content</button>
            </a>
        </div>
    );
};

export default Contact;